<template>
  <div>
    <v-container class="app-context" fluid>
      <div class="context flext">
        <v-row>
          <v-col
            cols="12"
            class="text-center"
            style="font-weight: 500; font-size: 32px"
          >
            결제 상품
          </v-col>
          <v-col cols="12">
            <v-row class="text-center">
              <v-col :key="pro.id" v-for="pro in products">
                <v-hover v-slot:default="{ hover }">
                  <v-card class="mx-auto" color="grey lighten-4" width="300px">
                    <v-img
                      class="white--text align-end"
                      :aspect-ratio="0.707"
                      height="415px"
                      :src="'https://www.drrefer.net/api/pic/' + pro.attchId"
                    >
                      <!--<span
                                        style="
                                        position: relative;
                                        left: -50%;
                                        width: 100%;
                                        color: #000;
                                        font-size: 24px;
                                        top: 20px;
                                        "
                                        >{{ pro.prodNm }}
                                    </span>-->
                      <v-expand-transition>
                        <div
                          v-if="hover"
                          class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal display-3"
                          style="
                            height: 415px;
                            position: relative;
                            width: 100%;
                            background-color: #000000;
                            opacity: 0.65;
                            top: 0px;
                          "
                        >
                          <v-btn
                            v-if="hover"
                            v-bind:href="'/v/payment/designDetail/' + pro.id"
                            class=""
                            outlined
                            style="
                              color: #fff;
                              font-size: 18px;
                              font-wright: bold;
                            "
                            >VIEW</v-btn
                          >
                        </div>
                      </v-expand-transition>
                    </v-img>
                    <v-card-text class="text--primary">
                      <div>
                        <a
                          v-bind:href="'/v/payment/designDetail/' + pro.id"
                          style="text-decoration: none"
                          >{{ pro.prodNm }}</a
                        >
                      </div>
                      <div>
                        <v-icon style="margin-right: 5px"
                          >mdi-cash-multiple</v-icon
                        >{{ pro.price }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}
</style>

<script>
import AppHeader from "@/components/appHeader/AppHeaderTest";
import AppFooter from "@/components/AppFooter";
import SideMenu from "@/components/sidemenu/SideMenu";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
export default {
  components: { AppHeader, AppFooter, SideMenu },
  data() {
    return {
      sideSet: {
        menuName: "payment",
        sideTitle: "소개 디자인",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      headerOption: {
        navOrder: 0,
        isMain: true,
      },
      select: "Popularity",
      page: 1,
      products: [],
    };
  },
  created() {
    //this.isLoginActive();
    this.getProdList();
  },
  computed: {
    ...mapGetters("prod", ["GET_PROD_LIST"]),
    ...mapGetters("auth", ["GET_IS_LOGIN_ACTIVE"]),
  },
  methods: {
    ...mapActions("prod", ["BRING_PROD_LIST"]),
    ...mapActions("auth", ["IS_LOGIN_ACTIVE"]),
    goHome() {
      this.$router.push("/").catch(() => {});
    },
    /*async isLoginActive() {
      try {
        await this.IS_LOGIN_ACTIVE();
      } catch (error) {
        console.log(error);
        this.$router.push("/v/login");
      }
    },*/
    async getProdList() {
      const searchOpt = {
        pageNo: 1,
        recordsPerPage: 10,
      };
      try {
        await this.BRING_PROD_LIST(searchOpt).then(() => {
          //console.log(this.GET_PROD_LIST);
          var array = {};
          array = this.GET_PROD_LIST.list;

          for (var i = 0; i < array.length; i++) {
            array[i] = Object.assign({ id: i + 1 }, array[i]);
          }
          // console.log(array[0]);

          this.products = array;
        });
      } catch (error) {
        console.log("error :", error);
      }
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    async callPopWithSideIssue(subData) {
      // console.log("sub", subData);
      let pop;
      pop = {
        popType: "IssueChild",
        title: "부계정 관리",
        content:
          "발급할 부계정은 <b style='color:#ff0a00'>1" +
          localStorage.getItem("drId") +
          " </b>입니다.\n변경할 부계정의 비밀번호를 입력해주세요.",
        immediatly: true,
        functionValue: subData,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    initAuthInfo() {
      // console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
  },
};
</script>
